import { Divider, Drawer, Grid, IconButton, styled } from '@mui/material'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'

export const SIDEBAR_WIDTH = '330px'

export interface SidebarDrawerProps {
    open: boolean
    onClose: () => void
    children?: React.ReactNode
}

/**
 * Resusable Drawer component for the sidebar
 */
export function SidebarDrawer(props: SidebarDrawerProps) {
    const { open, children, onClose } = props

    return (
        <Drawer
            variant="persistent"
            anchor="left"
            open={open}
            sx={{
                position: 'relative',
                height: '200vh',
                width: SIDEBAR_WIDTH,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: SIDEBAR_WIDTH,
                    boxSizing: 'border-box',
                },
                zIndex: 0,
            }}
            PaperProps={{
                sx: {
                    backgroundColor: '#F4F5FF',
                    width: SIDEBAR_WIDTH,
                    position: 'absolute',
                    transition: 'none !important',
                },
            }}
        >
            <Grid
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '8px 24px',
                }}
            >
                <IconButton onClick={onClose} sx={{ alignSelf: 'flex-end', padding: '8px 10px 16px' }}>
                    <ChevronLeftIcon />
                </IconButton>
                <Divider sx={{ borderColor: 'white' }} />
                {children}
            </Grid>
        </Drawer>
    )
}

// Styles main component based on sidebar state, including open/close transition
export const Main = styled('main', {
    shouldForwardProp: (prop) => prop !== 'open',
})<{
    open?: boolean
}>(({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeIn,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
    ...(!open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${SIDEBAR_WIDTH}`,
    }),
}))
