import { useCallback, useEffect, useState } from 'react'
import { Grid, Container } from '@mui/material'
import { useStorageContext } from '../../context/Storage'
import dayjs from 'dayjs'
import { GridSamples } from '../../components/grid-samples'
import { SearchMenu } from './SearchMenu'
import { useParams } from 'react-router-dom'
import { useNotificationContext } from '../../context/NotificationContext'
import { getSamplesByDate } from '../../libs/api/samples/SampleAPI'

const INITIAL_START_DATE = dayjs(new Date(2000, 1, 1))

export default function Search() {
    const { projectId } = useParams()
    const { samplesSearch, updateSamplesSearch } = useStorageContext()
    const [start, setStart] = useState(dayjs().subtract(7, 'day'))
    const [end, setEnd] = useState(dayjs().add(1, 'day'))
    const [projects, setProjects] = useState([])
    const [loading, setLoading] = useState(false)
    const [shouldFetch, setShouldFetch] = useState(false)
    const openNotification = useNotificationContext().openNotification

    const samplesFetch = useCallback(async () => {
        setLoading(true)
        const { succeeded, data, errors } = await getSamplesByDate(start.toISOString(), end.toISOString(), projects)
        if (succeeded) {
            updateSamplesSearch({
                samples: data,
                start: start,
                end: end,
                projects: projects,
            })
        } else {
            openNotification(errors.message, errors.severity)
        }
        setLoading(false)
    }, [start, end, projects, updateSamplesSearch])

    useEffect(() => {
        if (isFromProjectPage(projectId, projects)) {
            setProjects([projectId])
            setStart(INITIAL_START_DATE)
            loadSamples()
        } else if (samplesSearch && samplesSearch.start) {
            setStart(dayjs(samplesSearch.start))
            setEnd(dayjs(samplesSearch.end))
            setProjects(samplesSearch.projects)
        }
    }, [])

    useEffect(() => {
        if (shouldFetch) {
            samplesFetch()
            setShouldFetch(false)
        }
    }, [shouldFetch, samplesFetch])

    const loadSamples = async () => {
        setShouldFetch(true)
    }

    return (
        <Container maxWidth={false} sx={{ padding: '20px' }}>
            <Grid container spacing={2}>
                <Grid
                    item
                    xs={12}
                    lg={2}
                    sx={{
                        minWidth: '230px',
                        paddingRight: '20px',
                        borderRight: '1px solid rgba(19, 19, 32, .12)',
                    }}
                >
                    <SearchMenu
                        start={start}
                        end={end}
                        loading={loading}
                        setStart={setStart}
                        setEnd={setEnd}
                        projectChange={setProjects}
                        onSearchClick={loadSamples}
                        initialProjects={projects}
                    />
                </Grid>
                <Grid item xs={12} lg={10}>
                    <GridSamples samples={samplesSearch && samplesSearch.samples} loading={loading} enableCheckbox={false} />
                </Grid>
            </Grid>
        </Container>
    )
}

/**
 * Check if component is loaded from the projects page and not yet cached
 * @param projectId The project Id from navigate param
 * @param projects List of projects loaded from context
 * @returns True if component is loaded from the projects page and not yet cached
 */
function isFromProjectPage(projectId: string, projects: any[]): boolean {
    return projectId && (!projects || projects.length !== 1 || projectId !== projects[0])
}
