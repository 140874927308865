import { Info } from '@mui/icons-material'
import { Button, Divider, Grid, Typography } from '@mui/material'
import { SidebarDrawer } from '../SidebarDrawer'
import { useContext } from 'react'
import { SampleDataContext } from '../../../../context/CustomContext'

export interface MetabolicPathwaysSidebarProps {
    open: boolean
    ssuRunIds: string[]
    onClose: () => void
}

export function MetabolicPathwaySidebar(props: MetabolicPathwaysSidebarProps) {
    const { open, ssuRunIds, onClose } = props
    const { selectedSsuRunId, setSelectedSsuRunId } = useContext(SampleDataContext)

    const handleAccessionChange = (ssuRunId: string) => {
        if (ssuRunId !== selectedSsuRunId) {
            setSelectedSsuRunId(ssuRunId)
        }
    }

    return (
        <SidebarDrawer open={open} onClose={onClose}>
            <Typography variant="subtitle1" padding="20px 20px 5px" color="rgba(78, 78, 97, 1)">
                WGS Accession
            </Typography>
            <Typography variant="caption" fontStyle="italic" letterSpacing={0.5} padding="0 20px 20px">
                Not available for this sample
            </Typography>
            <Divider sx={{ borderColor: 'white' }} />
            <Typography variant="subtitle1" sx={{ marginBottom: '10px', padding: '20px 20px 5px', color: 'rgba(78, 78, 97, 1)' }}>
                16S rRNA Accession
            </Typography>
            <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
                {ssuRunIds &&
                    ssuRunIds.map((ssuRunId) => (
                        <AccessionButton key={ssuRunId} ssuRunId={ssuRunId} isSelected={ssuRunId === selectedSsuRunId} handleClick={handleAccessionChange} />
                    ))}
            </Grid>
            <Divider sx={{ borderColor: 'white' }} />
            <Grid
                marginTop="30px"
                sx={{
                    display: 'flex',
                    padding: '20px 16px 15px',
                    border: '2px solid #1B49D4',
                    borderRadius: '12px',
                }}
            >
                <Info sx={{ marginRight: '15px', color: '#1B49D4' }} />
                <Grid>
                    <Typography variant="subtitle1" fontWeight="bold" color="rgba(19, 19, 32, 0.87)" marginBottom="4px">
                        Functional Profiles
                    </Typography>
                    <Typography variant="body2">
                        Functional profiles are generated for both WGS and 16S rRNA gene sequencing data. Genes are directly annotated in WGS data. In contrast, for 16S rRNA data
                        functional profiles are transitively inferred from the set of reactions present in organisms identified from the 16S rRNA data. Therefore, functional
                        profiles from WGS data are considered more accurate than those from 16S rRNA gene data.
                    </Typography>
                </Grid>
            </Grid>
        </SidebarDrawer>
    )
}

interface AccessionButtonProps {
    ssuRunId: string
    isSelected: boolean
    handleClick: (ssuRunId: string) => void
}

function AccessionButton(props: AccessionButtonProps) {
    const { ssuRunId, isSelected, handleClick } = props

    const backgroundColor = isSelected ? '#EBEEFD' : ''
    const dividerColor = isSelected ? '#1B49D4' : ''
    const textColor = isSelected ? '#1B49D4' : 'rgba(19, 19, 32, 0.87)'

    return (
        <Grid
            key={ssuRunId}
            sx={{
                display: 'flex',
                marginLeft: '20px',
                height: '32px',
            }}
        >
            <Button
                disabled={isSelected}
                onClick={() => handleClick(ssuRunId)}
                sx={{ width: '260px', padding: '0 0 0 10px', backgroundColor: backgroundColor, justifyContent: 'flex-start' }}
            >
                <Divider orientation="vertical" sx={{ borderColor: dividerColor, borderWidth: '1px' }} />
                <Typography variant="subtitle2" sx={{ paddingLeft: '10px', color: textColor, '&:hover': { color: '#1B49D4' } }}>
                    {ssuRunId}
                </Typography>
            </Button>
        </Grid>
    )
}
