import { InputAdornment, SxProps, TextField, Theme } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'

export interface SearchTextProps {
    disabled: boolean
    label: string
    onSearchChange: (searchTerm: string) => void
    sx?: SxProps<Theme>
}

/**
 * The SearchText component is a text field common component to be used whenever a keyword search
 * is necessary, providing consistent styling and functionality across the platform
 */
export default function SearchText(props: SearchTextProps) {
    const { disabled, label, onSearchChange, sx } = props

    let timer = null
    const handleSearchTerm = (event) => {
        if (timer) {
            clearTimeout(timer)
        }
        timer = setTimeout(() => {
            onSearchChange(event.target.value)
        }, 2000)
    }

    return (
        <TextField
            disabled={disabled}
            type="search"
            id="search"
            label={label}
            onChange={handleSearchTerm}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <SearchIcon />
                    </InputAdornment>
                ),
                sx: { borderRadius: '12px' },
                size: 'small',
            }}
            sx={sx ? sx : {}}
        />
    )
}

/**
 * Verify if keyword is found in the string
 * TODO: Excludes special characters from search
 *
 * @param text The string where the keyword search will be performed
 * @param keyword The string to search for in text
 * @returns True if the keyword is found in text. False otherwise.
 */
export function isKeywordInText(text: string, keyword: string): boolean {
    if (!text || !keyword) {
        return false
    }

    return text.toLowerCase().indexOf(keyword.toLowerCase()) > -1
}

/**
 * Wraps the keywords found in span element with specific classname for highlighting
 * on render
 *
 * @param text The text where the containing keyword will be highlighted
 * @param keyword The keyword string to search and highlight
 * @returns The text with appropriate HTML element wrapper to be highlighted on render
 */
export function highlightKeyword(text: string, keyword: string): string {
    if (!keyword || !text) {
        return text
    }

    const regex = new RegExp(`(${keyword})`, 'gi')
    const parts = text.split(regex)

    const highlightedPart = parts.map((part) => {
        const isMatch = regex.test(part)
        return isMatch ? `<span class="keyword">${part}</span>` : `${part}`
    })

    return highlightedPart.join('')
}
