import { useState, useEffect, useContext } from 'react'
import { Grid, Box, IconButton } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { GeochemicalDataSidebar } from './GeochemicalDataSidebar'
import { Main } from '../SidebarDrawer'
import { NotesCardDrawer } from '../NotesCardDrawer'
import { NotesCardState, renderCardItems } from './NotesCard'
import { GeochemicalTab, GeochemicalTabState } from './GeochemicalTab'
import SearchText from '../../../../components/SearchText'
import ElementsTable from './ElementsTable'
import PeriodicTable from './PeriodicTable'
import { getGeochemicalDataForSample } from '../../../../libs/api/geochemical/GeochemicalAPI'
import { SampleDataContext } from '../../../../context/CustomContext'
import { useNotificationContext } from '../../../../context/NotificationContext'

export default function GeochemicalData() {
    const [showSidebar, setShowSidebar] = useState<boolean>(true)
    const [notesCardState, setNotesCardState] = useState<NotesCardState>(NotesCardState.Disabled)
    const [geochemicalTabState, setGeochemicalTabState] = useState<GeochemicalTabState>(GeochemicalTabState.periodicTable)
    const [loading, setLoading] = useState(false)
    const [searchTerm, setSearchTerm] = useState('')
    const openNotification = useNotificationContext().openNotification

    const [originalElements, setOriginalElements] = useState([])
    const [filteredElements, setFilteredElements] = useState([])

    // State for compounds
    const [originalCompounds, setOriginalCompounds] = useState([])
    const [filteredCompounds, setFilteredCompounds] = useState([])

    const { setExportButtonEnabled, sampleData } = useContext(SampleDataContext)

    useEffect(() => {
        setExportButtonEnabled(geochemicalTabState !== GeochemicalTabState.periodicTable)
    }, [geochemicalTabState])

    useEffect(() => {
        async function fetchData() {
            setLoading(true)

            const { succeeded, data, errors } = await getGeochemicalDataForSample(sampleData.id)
            if (succeeded) {
                const provider = data.provider
                const elements = mapAnalytes(data.elements, provider)
                const compounds = mapAnalytes(data.compounds, provider)

                setOriginalElements(elements)
                setFilteredElements(elements)

                setOriginalCompounds(compounds)
                setFilteredCompounds(compounds)
            } else {
                setOriginalElements([])
                setFilteredElements([])
                openNotification(errors.message, errors.severity)
            }

            setLoading(false)
        }

        fetchData()
    }, [sampleData])

    const handleSearchTerm = async (term: string) => {
        const lowerCaseTerm = term.toLowerCase()
        setSearchTerm(lowerCaseTerm)
        // Filter elements
        const filteredElementsData = originalElements.filter((element) => {
            return Object.values(element).some((value) => value.toString().toLowerCase().includes(lowerCaseTerm))
        })

        // Filter compounds
        const filteredCompoundsData = originalCompounds.filter((compound) => {
            return Object.values(compound).some((value) => value.toString().toLowerCase().includes(lowerCaseTerm))
        })

        setFilteredElements(filteredElementsData)
        setFilteredCompounds(filteredCompoundsData)
    }

    return (
        <Box sx={{ display: 'flex', width: '100%' }}>
            <GeochemicalDataSidebar open={showSidebar} onClose={() => setShowSidebar(false)} />
            <Main open={showSidebar} sx={{ width: 'auto', flex: 1, overflow: 'hidden' }}>
                <Grid display="flex" flexDirection="row">
                    <IconButton
                        onClick={() => setShowSidebar(true)}
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        sx={{
                            mar: 2,
                            paddingTop: '2px',
                            ...(showSidebar && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                </Grid>

                <Grid display="flex" flexDirection="row" alignItems="center">
                    <GeochemicalTab tabState={geochemicalTabState} onTabStateChange={(value) => setGeochemicalTabState(value)} sx={{ padding: '20px 0' }} />

                    <SearchText
                        label="Seach by keyword..."
                        disabled={geochemicalTabState === GeochemicalTabState.periodicTable}
                        onSearchChange={handleSearchTerm}
                        sx={{
                            width: '30%',
                            minWidth: '250px',
                            marginLeft: '20px',
                        }}
                    />
                </Grid>

                {geochemicalTabState === GeochemicalTabState.periodicTable ? <PeriodicTable elementData={originalElements} /> : null}
                {geochemicalTabState === GeochemicalTabState.elementTable ? <ElementsTable elements={filteredElements} loading={loading} searchTerm={searchTerm} /> : null}
                {geochemicalTabState === GeochemicalTabState.compoundTable ? <ElementsTable elements={filteredCompounds} loading={loading} searchTerm={searchTerm} /> : null}
            </Main>
            <NotesCardDrawer
                open={notesCardState !== NotesCardState.Disabled}
                title={notesCardState === NotesCardState.Attributes ? 'Attributes' : 'Term Helper'}
                onClose={() => setNotesCardState(NotesCardState.Disabled)}
            >
                {renderCardItems(notesCardState, null)}
            </NotesCardDrawer>
        </Box>
    )
}

function mapAnalytes(analytesList, provider) {
    // Add an 'id' field to each analyte using the index
    analytesList = analytesList.map((item, index) => ({
        ...item,
        id: index + 1, // Adding 1 to start IDs from 1 instead of 0
        provider: provider,
        valueWithUnit: `${item.value}\u00A0${item.unit}`, // \u00A0 is a non-breaking space. Using a regular space caused an issue
        //the issue was that a " symbol would always appear before the % symbol.
    }))

    return analytesList
}
