import { createRoot } from 'react-dom/client'
import { MsalProvider } from '@azure/msal-react'
import { msalInstance } from './libs/api/ServicesAuth'
import App from './App'
import './index.css'

const container = document.getElementById('root')
const root = createRoot(container as HTMLElement)
root.render(
    <MsalProvider instance={msalInstance}>
        <App />
    </MsalProvider>
)
