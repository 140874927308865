import { useState, useEffect, useContext } from 'react'
import { Avatar, Container, Grid, List, Typography } from '@mui/material'
import DataManagerGrid from './DataManagerGrid'
import { GridFileTypeOption, fileTypeNavItems, SolidGeochemicalFile } from './ManageData.model'
import { getSolidGeochemicalFilesForUser } from '../../libs/api/geochemical/GeochemicalAPI'
//import { useNotificationContext } from '../../context/NotificationContext'
import FileTypeNavItem from './FileTypeNavItem'
import { UserProfileContext } from '../../context/CustomContext'

/**
 * The Projects Management component
 */
export default function DataManagerPage() {
    const [selectedFileTypeOption, setSelectedFileTypeOption] = useState<GridFileTypeOption>(GridFileTypeOption.solidGeochemicalData)
    const [fileList, setFileList] = useState<SolidGeochemicalFile[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const userProfile = useContext(UserProfileContext)
    //const openNotification = useNotificationContext().openNotification

    const handleGridProjectSelection = (selectedGridProject: GridFileTypeOption) => {
        setSelectedFileTypeOption(selectedGridProject)
    }

    useEffect(() => {
        async function fetchData() {
            setLoading(true)
            const solidGeochemicalFiles = await getSolidGeochemicalFilesForUser()

            setFileList(solidGeochemicalFiles)

            setLoading(false)
        }

        fetchData()
    })

    return (
        <Container maxWidth={false}>
            <Grid container spacing={2} wrap="nowrap">
                <Grid
                    item
                    xs={2}
                    sx={{
                        minWidth: '280px',
                        paddingRight: '20px',
                        borderRight: '1px solid rgba(19, 19, 32, .12)',
                    }}
                >
                    <Grid
                        sx={{
                            p: '20px 0',
                            borderBottom: '1px solid rgba(19, 19, 32, .12)',
                        }}
                    >
                        <Grid
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                marginBottom: '10px',
                            }}
                        >
                            <Avatar alt="Anonimous Avatar" src="/images/avatar-anonimous.svg" />
                            <Typography variant="subtitle1" 
                                sx={{ 
                                    marginLeft: '10px',
                                    overflow: 'hidden'
                                }}>
                                <Grid sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                    {userProfile.email}
                                </Grid>
                                {userProfile.organizationName && 
                                    <small>{userProfile.organizationName}</small>
                                }
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item sx={{ paddingTop: '20px' }}>
                        <List>
                            {fileTypeNavItems.map((item) => (
                                <FileTypeNavItem key={item.gridOption} item={item} isSelected={item.gridOption === selectedFileTypeOption} onSelect={handleGridProjectSelection} />
                            ))}
                        </List>
                    </Grid>
                </Grid>
                <Grid item xs={10}>
                    <DataManagerGrid gridTitle={fileTypeNavItems.find((x) => x.gridOption === selectedFileTypeOption).label} files={fileList} loading={loading} />
                </Grid>
            </Grid>
        </Container>
    )
}
