import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { Button, Chip, Grid, Typography } from '@mui/material'
import { DataGridPremium, GridColumnMenu, GridColumnMenuProps, GridEventListener, useGridApiRef } from '@mui/x-data-grid-premium'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

export interface ProjectsGridProps {
    loading: boolean
    projects: any
    onEditProjectView: (projectId: string) => void
}

/**
 * The Project Management Grid component
 */
export default function ProjectsGrid(props: ProjectsGridProps) {
    const { loading, projects, onEditProjectView } = props
    const apiRef = useGridApiRef()
    const navigate = useNavigate()

    const handleEditProject = (projectId: string) => {
        onEditProjectView(projectId)
    }

    const handleViewSamples = (projectId: string) => {
        navigate(`/search/${projectId}`)
    }

    const builtColumns = buildColumns()

    const onRowClick: GridEventListener<'rowClick'> = (params) => {
        apiRef.current.toggleDetailPanel(params.id)
    }

    function CustomColumnMenu(props: GridColumnMenuProps) {
        return (
            <GridColumnMenu
                {...props}
                slots={{
                    columnMenuGroupingItem: null,
                    columnMenuPinningItem: null,
                    columnMenuColumnsItem: null,
                }}
            />
        )
    }

    return (
        <Grid>
            <DataGridPremium
                getRowId={(r) => r.id}
                apiRef={apiRef}
                pagination
                autoHeight
                pageSizeOptions={[25, 50, 100]}
                sortingOrder={['asc', 'desc']}
                rows={projects}
                loading={loading}
                columns={builtColumns}
                initialState={initialState}
                disableRowSelectionOnClick
                onRowClick={onRowClick}
                slots={{
                    detailPanelExpandIcon: KeyboardArrowDown,
                    detailPanelCollapseIcon: KeyboardArrowUp,
                    columnMenu: CustomColumnMenu,
                    noRowsOverlay: () => <NoProjectsOverlay />,
                }}
                sx={{
                    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                        outline: 'none',
                    },
                    '.MuiDataGrid-row:hover': {
                        cursor: 'pointer',
                    },
                    '.MuiDataGrid-columnHeaderTitle': {
                        fontWeight: 'bold',
                    },
                    '& .project-name': {
                        fontWeight: 'bold',
                    },
                    borderTop: 'none',
                    borderRight: 'none',
                    borderLeft: 'none',
                }}
                getDetailPanelContent={({ row }) => getDetailPanel(row, handleEditProject, handleViewSamples)}
                getDetailPanelHeight={() => 'auto'}
            />
        </Grid>
    )
}

/**
 * Component to render as fallback when no data is available
 */
const NoProjectsOverlay = () => {
    const [show, setShow] = useState(false)

    useEffect(() => {
        const timeout = setTimeout(() => setShow(true), 300)
        return () => clearTimeout(timeout)
    }, [])

    if (!show) return null
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
            }}
        >
            <p>No projects found</p>
        </div>
    )
}

/**
 * The datagrid columns setup
 */
const buildColumns = () => {
    return [
        {
            field: 'name',
            headerName: 'Name',
            flex: 3,
            cellClassName: 'project-name',
            availableAggregationFunctions: [],
        },
        {
            field: 'samples',
            headerName: 'Samples',
            flex: 1,
            availableAggregationFunctions: [],
        },
        {
            field: 'contributors',
            headerName: 'Contributors',
            flex: 1,
            availableAggregationFunctions: [],
        },
        {
            field: 'owner',
            headerName: 'Owner',
            flex: 2,
            availableAggregationFunctions: [],
        },
        {
            field: 'status',
            headerName: 'Project Status',
            flex: 1,
            availableAggregationFunctions: [],
            renderCell: (params) => getStatus(params.row.status),
        },
        {
            field: 'lastModified',
            headerName: 'Last Modified',
            flex: 1,
            availableAggregationFunctions: [],
        },
    ]
}

/**
 * The Chip Status component rendered in the status column for a project item
 * @param status The project status
 * @returns The chip component to be rendered in the project status column
 */
const getStatus = (status: string) => {
    const color = status === 'open' ? 'success' : 'default'
    const backgroundColor = status === 'open' ? '#F7FAF7' : '#F7F7F7'

    return (
        <Chip
            style={{ paddingTop: '1px' }}
            label={status}
            color={color}
            disabled={status === 'archived'}
            size="medium"
            variant="outlined"
            sx={{ backgroundColor: backgroundColor }}
        />
    )
}

/**
 * The child panel component for a project item in the Grid
 * @param row The grid row object related to a project item
 * @param onEditProject Callback when project button in panel is clicked
 * @param onViewSamples Callback when View Smaples in panel is clicked
 * @returns The rendering component for the project details panel
 */
function getDetailPanel(row: any, onEditProject: (projectId: string) => void, onViewSamples: (projectId: string) => void) {
    return (
        <Grid
            container
            sx={{
                display: 'flex',
                flexDirection: 'row',
                padding: '24px',
                borderBottom: '1px solid rgba(19, 19, 32, 0.12)',
                borderTop: '1px solid rgba(27, 73, 212, 1)',
                backgroundImage: 'linear-gradient(rgba(240, 240, 255, 0.4), rgba(240, 240, 255, 0.2))',
                boxShadow: '3',
            }}
        >
            <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="body2" fontWeight="600" color="rgba(19, 19, 32, 0.6)">
                    Description
                </Typography>
                <Typography variant="body2" color="rgba(19, 19, 32, 0.6)">
                    {row.description}
                </Typography>
            </Grid>
            <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    variant="text"
                    color="primary"
                    sx={{
                        display: `${row.isUserMember ? '' : 'none'}`,
                        height: '36px',
                        padding: '9px 16px',
                        marginLeft: 'auto',
                        borderRadius: '12px',
                        alignItems: 'flex-start',
                    }}
                    onClick={() => onEditProject(row.id)}
                >
                    <Typography variant="body2" fontWeight="700" textTransform="capitalize">
                        Edit project
                    </Typography>
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    sx={{
                        height: '36px',
                        padding: '9px 16px',
                        marginLeft: '15px',
                        borderRadius: '12px',
                        alignItems: 'flex-start',
                    }}
                    onClick={() => onViewSamples(row.id)}
                >
                    <Typography variant="body2" textTransform="capitalize">
                        View samples
                    </Typography>
                </Button>
            </Grid>
        </Grid>
    )
}

/**
 * The datagrid initial state
 */
const initialState = {
    pagination: {
        paginationModel: { pageSize: 50, page: 0 },
    },
}
