import { Stack, Typography } from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import { LoadingButton } from '@mui/lab'
import dayjs from 'dayjs'
import { ProjectSelector } from './ProjectSelector'

interface SearchMenuProps {
    start: dayjs.Dayjs
    end: dayjs.Dayjs
    loading: boolean
    setStart: (value: React.SetStateAction<dayjs.Dayjs>) => void
    setEnd: (value: React.SetStateAction<dayjs.Dayjs>) => void
    projectChange: (projects: any) => void
    onSearchClick: () => Promise<void>
    initialProjects?: string[]
}

export function SearchMenu(props: SearchMenuProps) {
    const { start, end, loading, setStart, setEnd, projectChange, onSearchClick, initialProjects } = props
    return (
        <Stack spacing={2}>
            <Typography variant="h6" component="h5">
                Sample Search
            </Typography>
            <DesktopDatePicker label="Start" value={dayjs(start)} onChange={(newValue) => setStart(newValue)} />
            <DesktopDatePicker label="End" value={dayjs(end)} onChange={(newValue) => setEnd(newValue)} />
            <ProjectSelector onChange={projectChange} initialProjects={initialProjects} />
            <LoadingButton variant="contained" color="primary" loading={loading} onClick={onSearchClick}>
                Search
            </LoadingButton>
        </Stack>
    )
}
