import { GridProjectOptions } from '../../../pages/projects'
import BaseAPI, { ApiResponse } from '../BaseAPI'
import { ProjectMembersDTO, ProjectDTO, ProjectGridDTO, ProjectInviteDTO } from './ProjectDTO'
import { mapProjectGridDtoToProjectGrid, mapProjectInvitesDtoToProjectInvites, mapProjectMembersDtoToProjectMembers } from './ProjectMapper'
import { Project, ProjectInvite, ProjectMembers, UpdateProjectPayload } from './ProjectModel'

/**
 * Creates a new project entity in the database
 * @param project The project model to be created
 * @returns Success or failure. No data included in the response
 */
export async function createProject(project: UpdateProjectPayload): Promise<ApiResponse<Project>> {
    const response = await BaseAPI.create<ProjectDTO>('/project/create-project', project)
    return {
        ...response,
        data: null,
    }
}

/**
 * Updates a project property in the database
 * @param projectPayload The updated project model to be
 * @returns Success or failure. No data included in the response
 */
export async function updateProject(projectPayload: UpdateProjectPayload): Promise<ApiResponse<Project>> {
    const response = await BaseAPI.update<ProjectDTO>('/project', projectPayload)
    return {
        ...response,
        data: null,
    }
}

/**
 * Deletes a project from the database
 * @param projectId The Id of the project to be deleted
 * @returns Success or failure. No data included in the response
 */
export async function deleteProject(projectId: string): Promise<ApiResponse<Project>> {
    const config = { params: { projectId } }
    const response = await BaseAPI.delete<ProjectDTO>('/project', config)
    return {
        ...response,
        data: null,
    }
}

/**
 * Updates the user access level for a given project
 * @param projectId The project Id
 * @param userEmail The email of the target user to have it's level updated
 * @param admin Flag to indicate if new level is admin
 * @param owner Flag to indicate if new level is owner
 * @returns Success or failure. No data included in the response
 */
export async function updateUsersAccessLevel(projectId: string, userEmail: string, admin: boolean, owner: boolean): Promise<ApiResponse<Project>> {
    const config = {
        params: {
            projectId: projectId,
            userEmail: userEmail,
            admin: admin,
            owner: owner,
        },
    }
    const response = await BaseAPI.patch<ProjectDTO>('/project/user', null, config)
    return {
        ...response,
        data: null,
    }
}

/**
 * Removes user access from given project
 * @param projectId The id of the project
 * @param userEmail The email of the user who's access is being revoked
 * @param isAdmin If this user is currently an admin of the project
 * @returns Success or failure. No data included in the response
 */
export async function removeUserFromProject(projectId: string, userEmail: string, isAdmin: boolean): Promise<ApiResponse<Project>> {
    const config = {
        params: { projectId: projectId, userEmail: userEmail, admin: isAdmin },
    }
    const response = await BaseAPI.delete<ProjectDTO>('/project/user', config)
    return {
        ...response,
        data: null,
    }
}

/**
 * Gets project data for the ProjectsDataGrid page
 * @param filter The grid project option selected
 * @returns An array of grid project data
 */
export async function getProjectsDataGrid(filter: GridProjectOptions): Promise<ApiResponse<Project[]>> {
    let queryParam
    switch (filter) {
        case GridProjectOptions.shared:
            queryParam = 'shared_with_me'
            break
        case GridProjectOptions.public:
            queryParam = 'public_projects'
            break
        default:
            queryParam = 'my_projects'
            break
    }

    const config = { params: { category: queryParam } }
    const response = await BaseAPI.getMany<ProjectGridDTO>('/project/datagrid', null, config)

    const projectsModel = response.data && response.data.map((projectGridDto) => mapProjectGridDtoToProjectGrid(projectGridDto))

    return {
        ...response,
        data: projectsModel,
    }
}

/**
 * Gets all the members from a given project
 * @param projectId The id of the project
 * @returns An array including all members of the project
 */
export async function getProjectUsers(projectId: string): Promise<ApiResponse<ProjectMembers>> {
    const response = await BaseAPI.get<ProjectMembersDTO>('/project/users', projectId)

    const projectMembersModel = response.data && mapProjectMembersDtoToProjectMembers(response.data)

    return {
        ...response,
        data: projectMembersModel,
    }
}

/**
 * Gets all projects invites pending for current user
 * @returns The project invites for the current users
 */
export async function getProjectInvites(): Promise<ApiResponse<ProjectInvite[]>> {
    const response = await BaseAPI.getMany<ProjectInviteDTO>('/project/invites')

    const projectInvitesModel = response.data && mapProjectInvitesDtoToProjectInvites(response.data)

    return {
        ...response,
        data: projectInvitesModel,
    }
}
