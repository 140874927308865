import Box from '@mui/system/Box'
import { Typography } from '@mui/material'

export interface ElementBoxProps {
    elementName: string
    elementValue: string
}

export function ElementBox(props: ElementBoxProps) {
    const { elementName, elementValue } = props
    return (
        <Box
            sx={{
                borderRadius: 2,
                backgroundColor: 'rgba(27, 73, 212, 1)',
                width: 64,
                height: 64,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Typography variant="body1" color="white" fontWeight="bold" textAlign="center">
                {elementName}
            </Typography>
            <Typography variant="caption" color="white" textAlign="center">
                {elementValue}
            </Typography>
        </Box>
    )
}
