import { useState } from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { MailOutline } from '@mui/icons-material'
import { Checkbox, FormControl, FormControlLabel, Grid, MenuItem, Select, Typography } from '@mui/material'
import { ReactMultiEmail } from 'react-multi-email'
import 'react-multi-email/dist/style.css'
import { useNotificationContext } from '../../../../context/NotificationContext'
import ProgressIndicator from '../../../../components/ProgressIndicator'
import { UserRole } from '../../Projects.model'
import { InviteUserData, InviteUserResponse } from '../../../../libs/api/users/UserModel'
import { inviteUsers } from '../../../../libs/api/users/UserAPI'

export interface ShareProjectButtonProps {
    projectId: string
    disabled: boolean
    onLoadInvitedUsers: () => void
}

/**
 * The email inviation component that includes the invitation form
 */
export default function EmailInviteButton(props: ShareProjectButtonProps) {
    const { projectId, disabled, onLoadInvitedUsers } = props

    const openNotification = useNotificationContext().openNotification
    const [open, setOpen] = useState(false)
    const [emails, setEmails] = useState<string[]>([])
    const [focused, setFocused] = useState(false)
    const [notifyPeople, setNotifyPeople] = useState<boolean>(true)
    const [message, setMessage] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)
    const [accessLevel, setAccessLevel] = useState<UserRole>(UserRole.user)

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClickClose = () => {
        setOpen(false)
    }

    const handleSendInvites = async () => {
        setLoading(true)

        const inviteDataArray: InviteUserData[] = emails.map((email) => ({
            projectId: projectId,
            userEmail: email,
            isAdmin: accessLevel === UserRole.admin,
            shouldSendEmail: notifyPeople,
            message: message,
        }))

        await inviteUsers(inviteDataArray)
            .then((responses) => {
                const responseMessage = BuildNotificationMessage(responses)
                openNotification(responseMessage)
                onLoadInvitedUsers()
            })
            .catch((reason) => {
                openNotification(`Error: ${reason.message}`, 'error')
            })
            .finally(() => {
                setLoading(false)
                setOpen(false)
            })
    }

    const handleMessageChange = (event) => {
        setMessage(event.target.value)
    }

    const handleRoleChange = (role: UserRole) => {
        setAccessLevel(role)
    }

    // Adjusting ReactMultiEmail styling
    const multiEmailBorderColor = focused ? 'rgba(27, 73, 212)' : 'rgba(196, 196, 196)'
    const multiEmailBorderWidth = focused ? '2px' : '1px'
    const multiEmailMarginBottom = focused ? '0' : '2px' // Compensates heigh increase from border  width change

    return (
        <div>
            <Button
                disabled={disabled}
                variant="outlined"
                onClick={handleClickOpen}
                sx={{
                    padding: '10px 10px',
                    borderRadius: '10px',
                    margin: '19px 0 19px',
                }}
            >
                <MailOutline sx={{ padding: 'auto', marginRight: '10px' }} />
                <Typography textTransform="capitalize">Email Invite</Typography>
            </Button>
            <Dialog open={open} onClose={handleClickClose} fullWidth maxWidth="md" PaperProps={{ style: { borderRadius: '12px' } }}>
                <DialogTitle>Share with</DialogTitle>
                <DialogContent sx={{ width: '900px' }}>
                    <Grid container>
                        <ReactMultiEmail // https://www.npmjs.com/package/react-multi-email
                            placeholder="Enter emails"
                            emails={emails}
                            onChange={(_emails: string[]) => {
                                setEmails(_emails)
                            }}
                            autoFocus={true}
                            onFocus={() => setFocused(true)}
                            onBlur={() => setFocused(false)}
                            style={{
                                borderRadius: '12px',
                                borderColor: multiEmailBorderColor,
                                borderWidth: multiEmailBorderWidth,
                                marginBottom: multiEmailMarginBottom,
                                padding: '8px',
                            }}
                            getLabel={(email, index, removeEmail) => {
                                return (
                                    <div data-tag key={index}>
                                        <div data-tag-item>{email}</div>
                                        <span data-tag-handle onClick={() => removeEmail(index)}>
                                            ×
                                        </span>
                                    </div>
                                )
                            }}
                        />
                        <FormControl variant="standard" sx={{ padding: '5px' }}>
                            <Select
                                labelId="role-selection-label"
                                id="role-selection"
                                disableUnderline
                                value={accessLevel}
                                onChange={(x) => handleRoleChange(x.target.value as UserRole)}
                            >
                                <MenuItem value={UserRole.admin}>Admin</MenuItem>
                                <MenuItem value={UserRole.user}>Contributor</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <FormControlLabel
                        label="Notify People"
                        sx={{ marginLeft: '3px' }}
                        control={<Checkbox aria-label="Notify people" checked={notifyPeople} onChange={() => setNotifyPeople(!notifyPeople)} />}
                    />
                    <TextField
                        id="message"
                        label="Message"
                        name="message"
                        value={message}
                        variant="outlined"
                        multiline
                        fullWidth
                        rows={10}
                        InputProps={{
                            sx: { borderRadius: '12px' },
                        }}
                        onChange={(e) => handleMessageChange(e)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClickClose}
                        sx={{
                            padding: '6px 16px',
                            borderRadius: '10px',
                            margin: '19px 0 19px auto',
                        }}
                    >
                        <Typography textTransform="capitalize">Cancel</Typography>
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSendInvites}
                        sx={{
                            padding: '6px 16px',
                            borderRadius: '10px',
                            margin: '19px 20px 19px 5px',
                        }}
                    >
                        <Typography textTransform="capitalize">Send</Typography>
                    </Button>
                </DialogActions>
                <ProgressIndicator open={loading}></ProgressIndicator>
            </Dialog>
        </div>
    )
}

/**
 * Builds the notification message displayed to user after invitation action is completed
 * @param responses The response data from the invitation action
 * @returns The notification text with invitation action status
 */
function BuildNotificationMessage(responses: InviteUserResponse[]): string {
    let responseMessage = ''
    responses.forEach((response) => {
        responseMessage = responseMessage + response.email + ': ' + response.note + '\n'
    })
    return responseMessage
}
