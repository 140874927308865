import { useEffect, useState } from 'react'
import { Button, Divider, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@mui/material'
import { Project, ProjectStatus } from '../../Projects.model'
import { MIN_PROJECT_NAME_LENGTH, getCurrentStatusHelperMessage, getIsPublicHelperMessage, isFormModified } from './DetailsForm.service'
import { PublicSamplePropertiesForm } from './PublicSamplePropsForm'
import { Delete } from '@mui/icons-material'
import ConfirmationDialog from '../../../../components/ConfirmationDialog'

const DELETE_PROJECT_DIALOG_TITLE = 'Delete Project'
const DELETE_PROJECT_DIALOG_MESSAGE = `The project will be deleted from M-MAP platform. This action cannot be reverted.
Please note that this action will fail if there are still samples associated to the project.`

interface DetailsFormProps {
    project: Project
    enableFormUpdate: boolean
    onFormModified: (isModified: boolean) => void
    onFormSubmit: (project: Project) => void
    onProjectDeleted: (projectId: string) => void
}

/**
 * The form component for project creation, update and deletion
 */
export default function DetailsForm(props: DetailsFormProps) {
    const { project, enableFormUpdate, onFormSubmit, onFormModified, onProjectDeleted } = props

    const [currentProject, setCurrentProject] = useState<Project>({
        ...project,
        isPublic: project.isPublic ? project.isPublic : false, // Remove this once IsPublic is present in all project items
        publicSampleProps: project.publicSampleProps ? project.publicSampleProps : [],
    })
    const [nameError, setNameError] = useState<boolean>(false)
    const [dialogOpen, setDialogOpen] = useState<boolean>(false)

    const isNewProject = project.id === null

    const handleDetailsFormChange = (event) => {
        setCurrentProject({
            ...currentProject,
            [event.target.name]: event.target.name === 'isPublic' ? event.target.value === 'true' : event.target.value,
        })
    }

    const handleAnonymizedPropertiesChange = (newAnonProps: string[]) => {
        setCurrentProject({
            ...currentProject,
            publicSampleProps: newAnonProps || [],
        })
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        if (validateForm()) {
            onFormSubmit(currentProject)
        }
    }

    const validateForm = (): boolean => {
        let isFormValid = true
        if (currentProject.name.length < MIN_PROJECT_NAME_LENGTH) {
            setNameError(true)
            isFormValid = false
        }
        return isFormValid
    }

    useEffect(() => {
        const isChanged = isFormModified(project, currentProject)
        onFormModified(isChanged)
    }, [project, currentProject, onFormModified])

    return (
        <form id="project-form" onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column' }}>
            <TextField
                id="outlined-error"
                name="name"
                label="Project Name"
                value={currentProject.name}
                variant="outlined"
                sx={{ marginTop: '20px', width: '100%' }}
                InputProps={{
                    sx: { borderRadius: '12px' },
                }}
                disabled={!enableFormUpdate}
                onChange={(e) => {
                    setNameError(false)
                    handleDetailsFormChange(e)
                }}
                error={nameError}
                helperText={nameError && 'Project name is too short. Please use a minimum of 5 characters.'}
            />

            <TextField
                id="organizationName"
                label="Organization"
                name="organizationName"
                value={currentProject.organizationName || ''}
                variant="outlined"
                sx={{ marginTop: '20px' }}
                InputProps={{
                    sx: { borderRadius: '12px' },
                }}
                disabled={true}
            />

            <TextField
                id="description"
                label="Description"
                name="description"
                value={currentProject.description || ''}
                variant="outlined"
                multiline
                rows={10}
                sx={{ marginTop: '20px' }}
                InputProps={{
                    sx: { borderRadius: '12px' },
                }}
                disabled={!enableFormUpdate}
                onChange={(e) => handleDetailsFormChange(e)}
            />

            <FormControl disabled={!enableFormUpdate}>
                <FormLabel id="project-status-radio-buttons-group-label" sx={{ marginTop: '20px' }}>
                    Project Status
                </FormLabel>
                <RadioGroup
                    id="status-radio-group"
                    row
                    aria-labelledby="project-status-radio-buttons-group-label"
                    value={currentProject.status || ProjectStatus.open}
                    name="status"
                    sx={{ margin: '0 0 0 14px' }}
                    onChange={(e) => handleDetailsFormChange(e)}
                >
                    <FormControlLabel value={ProjectStatus.open} control={<Radio />} label="Open for new samples" />
                    <FormControlLabel value={ProjectStatus.close} control={<Radio />} label="Close for new samples" />
                    <FormControlLabel value={ProjectStatus.archive} control={<Radio />} label="Archived" />
                </RadioGroup>
                <FormHelperText id="open">{getCurrentStatusHelperMessage(currentProject.status || ProjectStatus.open)}</FormHelperText>
            </FormControl>

            <Grid sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <FormControl disabled={!enableFormUpdate}>
                    <FormLabel id="access-settings-radio-buttons-group-label" sx={{ marginTop: '20px' }}>
                        Access Settings
                    </FormLabel>
                    <RadioGroup
                        id="ispublic-radio-group"
                        row
                        aria-labelledby="access-settings-radio-buttons-group-label"
                        value={currentProject.isPublic || false}
                        name="isPublic"
                        sx={{ margin: '0 0 0 14px' }}
                        onChange={(e) => handleDetailsFormChange(e)}
                    >
                        <FormControlLabel value={false} control={<Radio />} label="Private" />
                        <FormControlLabel value={true} control={<Radio />} label="Public" />
                    </RadioGroup>
                    <FormHelperText id="access-settings-helper-text">{getIsPublicHelperMessage(currentProject.isPublic || false)}</FormHelperText>
                </FormControl>

                {!isNewProject ? (
                    <Button disabled={!enableFormUpdate} onClick={() => setDialogOpen(true)} sx={{ marginRight: '30px', color: 'rgba(211, 47, 47, 1)' }}>
                        <Delete />
                        <Typography marginLeft="5px" textTransform="capitalize">
                            Delete Project
                        </Typography>
                    </Button>
                ) : null}
            </Grid>

            <ConfirmationDialog
                openDialog={dialogOpen}
                title={DELETE_PROJECT_DIALOG_TITLE}
                description={DELETE_PROJECT_DIALOG_MESSAGE}
                confirmText={'Confirm'}
                cancelText={'Cancel'}
                onConfirm={() => {
                    setDialogOpen(false)
                    onProjectDeleted(currentProject.id)
                }}
                onCancel={() => {
                    setDialogOpen(false)
                }}
            />

            {currentProject.isPublic && enableFormUpdate ? (
                <>
                    <Divider sx={{ margin: '10px 0' }} />
                    <PublicSamplePropertiesForm publicSampleProps={currentProject.publicSampleProps} onPublicSamplePropsChange={handleAnonymizedPropertiesChange} />
                </>
            ) : null}
        </form>
    )
}
