import { Fragment } from 'react'
import { Grid } from '@mui/material'
import EditSample from './EditSample'
import InfoCard from '../InfoCard'
import SampleMap from '../../../../components/Map'
import ProgressIndicator from '../../../../components/ProgressIndicator'
import { collectionFields, environmentalFields } from './Summary.model'

export interface SummaryProps {
    isSampleLoaded: boolean
    sampleData: any
    editSampleData: boolean
    setEditSampleData: (editMode: boolean) => void
}

/**
 * The Summary tab component for the samples-details page
 */
export default function Summary(props: SummaryProps) {
    const { isSampleLoaded, sampleData, editSampleData, setEditSampleData } = props

    return (
        <Grid container spacing={2}>
            {sampleData ? (
                editSampleData ? (
                    <Grid item xs={12}>
                        <EditSample setEditData={setEditSampleData} />
                    </Grid>
                ) : (
                    <Fragment>
                        <Grid item xs={4}>
                            <InfoCard title="Collection Information" gridItems={collectionFields} data={sampleData} />
                        </Grid>
                        <Grid item xs={4}>
                            <InfoCard title="Environmental Information" gridItems={environmentalFields} data={sampleData} />
                        </Grid>
                        <Grid item xs={4}>
                            <SampleMap />
                        </Grid>
                    </Fragment>
                )
            ) : (
                <Grid
                    item
                    xs={12}
                    sx={{
                        height: '600px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <p>Loading sample data</p>
                    <ProgressIndicator open={!isSampleLoaded}></ProgressIndicator>
                </Grid>
            )}
        </Grid>
    )
}
