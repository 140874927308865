import { Button, Divider, Grid, Typography } from '@mui/material'
import { SidebarDrawer } from '../SidebarDrawer'
import { useContext } from 'react'
import { SampleDataContext } from '../../../../context/CustomContext'

export interface TaxonomySidebarProps {
    open: boolean
    ssuRunIds: string[]
    onClose: () => void
}

export function TaxonomySidebar(props: TaxonomySidebarProps) {
    const { open, ssuRunIds, onClose } = props
    const { selectedSsuRunId, setSelectedSsuRunId } = useContext(SampleDataContext)

    const handleAccessionChange = (ssuRunId: string) => {
        if (ssuRunId !== selectedSsuRunId) {
            setSelectedSsuRunId(ssuRunId)
        }
    }

    return (
        <SidebarDrawer open={open} onClose={onClose}>
            <Typography variant="subtitle1" padding="20px 20px 5px" color="rgba(78, 78, 97, 1)">
                WGS Accession
            </Typography>
            <Typography variant="caption" fontStyle="italic" letterSpacing={0.5} padding="0 20px 20px">
                Not available for this sample
            </Typography>
            <Divider sx={{ borderColor: 'white' }} />
            <Typography variant="subtitle1" sx={{ marginBottom: '10px', padding: '20px 20px 5px', color: 'rgba(78, 78, 97, 1)' }}>
                16S rRNA Accession
            </Typography>

            <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
                {ssuRunIds &&
                    ssuRunIds.map((ssuRunId) => (
                        <AccessionButton key={ssuRunId} ssuRunId={ssuRunId} isSelected={ssuRunId === selectedSsuRunId} handleClick={handleAccessionChange} />
                    ))}
            </Grid>
        </SidebarDrawer>
    )
}

interface AccessionButtonProps {
    ssuRunId: string
    isSelected: boolean
    handleClick: (ssuRunId: string) => void
}

function AccessionButton(props: AccessionButtonProps) {
    const { ssuRunId, isSelected, handleClick } = props

    const backgroundColor = isSelected ? '#EBEEFD' : ''
    const dividerColor = isSelected ? '#1B49D4' : ''
    const textColor = isSelected ? '#1B49D4' : 'rgba(19, 19, 32, 0.87)'

    return (
        <Grid
            key={ssuRunId}
            sx={{
                display: 'flex',
                marginLeft: '20px',
                height: '32px',
            }}
        >
            <Button
                disabled={isSelected}
                onClick={() => handleClick(ssuRunId)}
                sx={{ width: '260px', padding: '0 0 0 10px', backgroundColor: backgroundColor, justifyContent: 'flex-start' }}
            >
                <Divider orientation="vertical" sx={{ borderColor: dividerColor, borderWidth: '1px' }} />
                <Typography variant="subtitle2" sx={{ paddingLeft: '10px', color: textColor, '&:hover': { color: '#1B49D4' } }}>
                    {ssuRunId}
                </Typography>
            </Button>
        </Grid>
    )
}
