import Box from '@mui/system/Box'
import { Typography } from '@mui/material'
import { ElementBox } from './ElementBox'

// Define the type for a single element
export interface Element {
    name: string
    value: string
}

export interface SidebarBoxProps {
    title: string
    elements: Element[]
}

export function SidebarBox(props: SidebarBoxProps) {
    const { title, elements } = props
    return (
        <Box sx={{ marginTop: 2, p: 2, borderRadius: 2, backgroundColor: 'white', boxShadow: 1 }}>
            <Typography variant="subtitle2" color="rgba(78, 78, 97, 1)" fontWeight="bold" sx={{ marginBottom: 2 }}>
                {title}
            </Typography>

            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                {elements.map((el, index) => (
                    <Box key={index}>
                        {' '}
                        {/* Adjust the flex-basis and max-width as needed */}
                        <ElementBox elementName={el.name} elementValue={el.value} />
                    </Box>
                ))}
            </Box>
        </Box>
    )
}
