import { Divider, Typography } from '@mui/material'
import { SidebarDrawer } from '../SidebarDrawer'
import { SidebarBox } from './SidebarMetalContainer'

export interface GeochemicalDataSidebarProps {
    open: boolean
    onClose: () => void
}

export function GeochemicalDataSidebar(props: GeochemicalDataSidebarProps) {
    const { open, onClose } = props
    return (
        <SidebarDrawer open={open} onClose={onClose}>
            <Typography variant="subtitle1" padding="20px 20px 5px" color="rgba(78, 78, 97, 1)" fontWeight="bold">
                Geochemical Analysis Summary
            </Typography>
            <Typography variant="caption" letterSpacing={0.5} color="rgba(27, 73, 212, 1)" padding="0 20px 20px">
                Customize summary (TBD)
            </Typography>
            <Divider sx={{ borderColor: 'white' }} />
            <SidebarBox
                title="Trace Metals"
                elements={[
                    { name: 'Zn', value: '0.01%' },
                    { name: 'Cu', value: '164 ppm' },
                    { name: 'Au', value: '10 ppm' },
                    { name: 'He', value: '1 ppm' },
                ]}
            ></SidebarBox>
            <SidebarBox
                title="Commercially Important Metals"
                elements={[
                    { name: 'Zn', value: '0.01%' },
                    { name: 'Cu', value: '164 ppm' },
                ]}
            ></SidebarBox>
            <SidebarBox
                title="Rare Find Elements"
                elements={[
                    { name: 'Zn', value: '0.01%' },
                    { name: 'Cu', value: '164 ppm' },
                    { name: 'Au', value: '10 ppm' },
                ]}
            ></SidebarBox>
        </SidebarDrawer>
    )
}
