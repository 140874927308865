import { PublicClientApplication, BrowserCacheLocation, InteractionRequiredAuthError, Configuration } from '@azure/msal-browser'
import axios from 'axios'

const configuration: Configuration = {
    auth: {
        clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
        authority: process.env.REACT_APP_AUTH_AUTHORITY,
        knownAuthorities: [process.env.REACT_APP_AUTH_KNOWN_AUTHORITIES],
        redirectUri: '/',
    },
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage, // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/caching.md
    },
}

export const msalInstance = new PublicClientApplication(configuration)

async function acquireAccessToken(msalInstance: PublicClientApplication) {
    const activeAccount = msalInstance.getActiveAccount() // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
    const accounts = msalInstance.getAllAccounts()

    if (!activeAccount && accounts.length === 0) {
        /*
         * User is not signed in. Throw error or wait for user to login.
         * Do not attempt to log a user in outside of the context of MsalProvider
         */
        return false
    }
    const accessTokenRequest = {
        scopes: [process.env.REACT_APP_ACCESS_TOKEN_SCOPE],
        account: activeAccount || accounts[0],
    }

    const authResult: any = await msalInstance.acquireTokenSilent(accessTokenRequest).catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
            // fallback to interaction when silent call fails
            return msalInstance.acquireTokenRedirect(accessTokenRequest)
        }
    })
    return authResult.accessToken
}

export const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    timeout: 40000,
    headers: { 'Content-Type': 'application/json' },
})

axiosInstance.interceptors.request.use(
    async function (config) {
        const accessToken = await acquireAccessToken(msalInstance)
        if (accessToken) {
            config.headers['Authorization'] = 'Bearer ' + accessToken
        }
        return config
    },
    function (error: any) {
        return Promise.reject(error)
    }
)
