import BaseAPI, { ApiResponse } from '../BaseAPI'
import { InviteUserDTO, ProjectInvitedUserDTO, UserDTO, ProjectInviteResponseDTO, UserRequestDTO, RespondUserInvitePayloadDTO } from './UserDTO'
import { mapProjectInvitesDtoToInvitedUsers, mapUserDtoToUser, mapUserRequestsDtoToUserRequests } from './UserMapper'
import { InviteUserData, InviteUserResponse, ProjectInvitedUser, User, UserRequest } from './UserModel'

/**
 * Gets current user properties
 * @returns The user properties of the current user
 */
export async function getUser(): Promise<ApiResponse<User>> {
    const response = await BaseAPI.get<UserDTO>('/profile?q=proxy')

    const userModel = response.data && mapUserDtoToUser(response.data)

    return {
        ...response,
        data: userModel,
    }
}

/**
 * Gets all users from project that are currently pending invitation acceptance
 * @param projectId The project Id
 * @returns List of users and admin property pending invitation acceptance
 */
export async function getInvitedUsers(projectId: string): Promise<ApiResponse<ProjectInvitedUser[]>> {
    const response = await BaseAPI.getMany<ProjectInvitedUserDTO>('/project/invites', projectId)

    const invitedUsersModel = response.data && mapProjectInvitesDtoToInvitedUsers(response.data)

    return {
        ...response,
        data: invitedUsersModel,
    }
}

/**
 * Gets all new M-MAP users pending approval
 * @returns List of users who is pending acceptance on M-MAP platform
 */
export async function getUserRequests(): Promise<ApiResponse<UserRequest[]>> {
    const response = await BaseAPI.getMany<UserRequestDTO>('/profile/user-requests')

    const invitedUsersModel = response.data && mapUserRequestsDtoToUserRequests(response.data)

    return {
        ...response,
        data: invitedUsersModel,
    }
}

/**
 * Responds a user request to join M-MAP. Either accepting or rejecting it.
 * @param userId The userId to be approved/declined
 * @param hasApproved If user has accepted or rejected the invite
 * @param organizationId The organizationId the user belongs to in case of approval
 * @param rejectReason The reason for declining the request in case of rejection
 * @returns Message with acceptance/rejection
 */
export async function respondUserRequest(userId: string, hasApproved: boolean, organizationId?: string, rejectReason?: string): Promise<ApiResponse<ProjectInviteResponseDTO>> {
    const payload: RespondUserInvitePayloadDTO = {
        id: userId,
        status: hasApproved,
        organizationId: organizationId,
        declinedReason: rejectReason,
    }

    const response = await BaseAPI.create<RespondUserInvitePayloadDTO>(`/profile/respond-user-request`, payload)

    return {
        ...response,
        succeeded: true,
        data: { message: `Request is ${hasApproved ? 'approved' : 'declined'}.` },
    }
}

/**
 * Responds a project invite. Either accepting or rejecting it.
 * @param projectId The target project Id to respond
 * @param hasAccepted If user has accepted or rejected the invite
 * @returns Message with acceptance/rejection
 */
export async function respondProjectInvite(projectId: string, hasAccepted: boolean): Promise<ApiResponse<ProjectInviteResponseDTO>> {
    const QSP = `?projectId=${projectId}&accept=${hasAccepted}`
    const response = await BaseAPI.update<ProjectInviteResponseDTO>(`/project/respond-invite${QSP}`, null)

    return response
}

/**
 * Invites users to a project
 * @param users The list of users to be invited
 * @returns A string containg the individual invite results
 */
export async function inviteUsers(users: InviteUserData[]): Promise<InviteUserResponse[]> {
    const response: InviteUserResponse[] = []

    const results = await Promise.allSettled(
        users.map((data: InviteUserData) =>
            inviteUser({
                projectId: data.projectId,
                userEmail: data.userEmail,
                admin: data.isAdmin,
                sendEmail: data.shouldSendEmail,
                message: data.message,
            })
        )
    )

    results.forEach((result: PromiseSettledResult<ApiResponse<string>>, index) => {
        // @ts-expect-error Expected value
        const resultObject = result.value
        let noteMessage = ''
        if (resultObject.succeeded) {
            noteMessage = 'Invitation sent'
        } else if (resultObject.data?.reason?.response?.status === 500) {
            noteMessage = 'Server error'
        } else if (resultObject.errors?.code === '409') {
            noteMessage = 'User is already invited'
        } else if (resultObject.errors?.code === 'NETWORK_FAILURE') {
            noteMessage = resultObject.errors.message
        } else {
            noteMessage = resultObject.data?.reason?.response?.data?.detail
        }
        response.push({
            email: users[index].userEmail,
            // @ts-expect-error Expected value
            status: result.value?.succeeded ? 'succeeded' : 'failed',
            note: noteMessage,
        })
    })

    return response
}

/** Private methods */

/**
 * Invites single user to a project
 * @param inviteUser The user and project payload data for the invite
 * @returns The APiResponse model from the invite API call
 */
async function inviteUser(inviteUser: InviteUserDTO): Promise<ApiResponse<string>> {
    const response = await BaseAPI.create<any>('/project/invite-user/', inviteUser)
    return response
}
