import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { Grid, Typography } from '@mui/material'
import { DataGridPremium, GridColumnMenu, GridColumnMenuProps, GridEventListener, useGridApiRef, GridActionsCellItem } from '@mui/x-data-grid-premium'
import { useEffect, useState } from 'react'
import DownloadIcon from '@mui/icons-material/Download'
import DeleteIcon from '@mui/icons-material/Delete'

export interface DataManagerGridProps {
    gridTitle: string
    loading: boolean
    files: any
}

/**
 * The Project Management Grid component
 */
export default function ManageDataGrid(props: DataManagerGridProps) {
    const { gridTitle, loading, files } = props
    const apiRef = useGridApiRef()

    const builtColumns = buildColumns()

    const onRowClick: GridEventListener<'rowClick'> = (params) => {
        apiRef.current.toggleDetailPanel(params.id)
    }

    function CustomColumnMenu(props: GridColumnMenuProps) {
        return (
            <GridColumnMenu
                {...props}
                slots={{
                    columnMenuGroupingItem: null,
                    columnMenuPinningItem: null,
                    columnMenuColumnsItem: null,
                }}
            />
        )
    }

    return (
        <Grid>
            <Typography variant="h4" style={{ padding: '20px 0' }}>
                {gridTitle}
            </Typography>
            <DataGridPremium
                getRowId={(r) => r.id}
                apiRef={apiRef}
                pagination
                autoHeight
                pageSizeOptions={[25, 50, 100]}
                sortingOrder={['asc', 'desc']}
                rows={files}
                loading={loading}
                columns={builtColumns}
                initialState={initialState}
                disableRowSelectionOnClick
                onRowClick={onRowClick}
                slots={{
                    detailPanelExpandIcon: KeyboardArrowDown,
                    detailPanelCollapseIcon: KeyboardArrowUp,
                    columnMenu: CustomColumnMenu,
                    noRowsOverlay: () => <NoProjectsOverlay />,
                }}
                sx={{
                    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                        outline: 'none',
                    },
                    '.MuiDataGrid-row:hover': {
                        cursor: 'pointer',
                    },
                    '.MuiDataGrid-columnHeaderTitle': {
                        fontWeight: 'bold',
                    },
                    '& .project-name': {
                        fontWeight: 'bold',
                    },
                    borderTop: 'none',
                    borderRight: 'none',
                    borderLeft: 'none',
                }}
                getDetailPanelHeight={() => 'auto'}
            />
        </Grid>
    )
}

/**
 * Component to render as fallback when no data is available
 */
const NoProjectsOverlay = () => {
    const [show, setShow] = useState(false)

    useEffect(() => {
        const timeout = setTimeout(() => setShow(true), 300)
        return () => clearTimeout(timeout)
    }, [])

    if (!show) return null
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
            }}
        >
            <p>No projects found</p>
        </div>
    )
}

/**
 * The datagrid columns setup
 */
const buildColumns = () => {
    return [
        {
            field: 'fileName',
            headerName: 'File Name',
            flex: 2,
            availableAggregationFunctions: [],
        },
        {
            field: 'associatedSampleIDs',
            headerName: 'Associated Sample IDs',
            flex: 2,
            availableAggregationFunctions: [],
        },
        {
            field: 'vendor',
            headerName: 'Vendor',
            flex: 1,
            availableAggregationFunctions: [],
        },
        {
            field: 'uploadDate',
            headerName: 'Uploaded On',
            flex: 2,
            availableAggregationFunctions: [],
        },
        {
            field: 'actions',
            type: 'actions',
            flex: 1,
            getActions: (params) => [
                <GridActionsCellItem key="downloadAction" icon={<DownloadIcon />} onClick={() => console.log('Download', params)} label="Download" />,
                <GridActionsCellItem key="deleteAction" icon={<DeleteIcon />} onClick={() => console.log('Delete', params)} label="Delete" />,
            ],
        },
    ]
}

/**
 * The datagrid initial state
 */
const initialState = {
    pagination: {
        paginationModel: { pageSize: 50, page: 0 },
    },
}
