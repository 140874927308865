import { createContext, useEffect, useContext, useState } from 'react'
import { appInsights } from '../libs/AppInsights'
import { StorageError } from '../libs/AppErrors'
import { GridProjectOptions } from '../pages/projects'

interface samplesSearch {
    samples: any[]
    start: any
    end: any
    projects: string[]
}

interface StorageContextType {
    samplesSearch: samplesSearch
    updateSamplesSearch: (samplesSearch: samplesSearch) => void
    getContextProjects: (projectOption: GridProjectOptions) => any[]
    updateContextProjects: (projectOption: GridProjectOptions, projects: any[]) => void
    clearLocalStorage: () => void
}

const StorageContext = createContext<StorageContextType | null>(null)

function clearLocalStorage() {
    try {
        window.localStorage.clear()
    } catch (e) {
        appInsights.trackException({
            ...StorageError.FailToClear,
        })
    }
}

export function StorageProvider({ children }: any) {
    const [samples, setSamples] = useState<any>()
    const [myProjects, setMyProjects] = useState<any[]>([])
    const [sharedProjects, setSharedProjects] = useState<any[]>([])
    const [publicProjects, setPublicProjects] = useState<any[]>([])

    const updateSamplesSearch = (samplesSearch: samplesSearch) => {
        setSamples(samplesSearch)
    }

    const getProjects = (projectOption: GridProjectOptions): any[] => {
        let projects = []
        switch (projectOption) {
            case GridProjectOptions.myprojects:
                projects = myProjects
                break
            case GridProjectOptions.shared:
                projects = sharedProjects
                break
            case GridProjectOptions.public:
                projects = publicProjects
                break
        }
        return projects
    }

    const updateProjects = (projectOption: GridProjectOptions, projects: any[]) => {
        switch (projectOption) {
            case GridProjectOptions.myprojects:
                setMyProjects(projects)
                break
            case GridProjectOptions.shared:
                setSharedProjects(projects)
                break
            case GridProjectOptions.public:
                setPublicProjects(projects)
                break
        }
    }

    const clearStorage = () => {
        // TODO: Send telemetry log
        clearLocalStorage()
    }

    useEffect(() => {
        if (samples) {
            setSamples(samples)
        }
    }, [samples])

    useEffect(() => {
        if (myProjects) {
            setMyProjects(myProjects)
        }
    }, [myProjects])

    useEffect(() => {
        if (sharedProjects) {
            setSharedProjects(sharedProjects)
        }
    }, [sharedProjects])

    useEffect(() => {
        if (publicProjects) {
            setPublicProjects(publicProjects)
        }
    }, [publicProjects])

    return (
        <StorageContext.Provider
            value={{
                samplesSearch: samples,
                updateSamplesSearch: updateSamplesSearch,
                getContextProjects: getProjects,
                updateContextProjects: updateProjects,
                clearLocalStorage: clearStorage,
            }}
        >
            {children}
        </StorageContext.Provider>
    )
}

export function useStorageContext() {
    return useContext(StorageContext)
}
