import { AxiosRequestConfig } from 'axios'
import { mapAxiosResponseError, mapAxiosResponse } from './BaseMapper'
import { axiosInstance } from './ServicesAuth'

const BASE_URL = process.env.REACT_APP_API_BASE_URL

/**
 * Response model from API to be used in front end component.
 * This should isolate component domain models from API raw data
 */
export interface ApiResponse<T> {
    succeeded: boolean
    data?: T
    errors?: ApiError
}

/**
 * Response error model from API to be used in front end component.
 * This should isolate component domain models from API raw data
 */
export interface ApiError {
    code: string
    message: string
    severity: string
}

/**
 * Base class for API calls using Axios library
 * This class already maps Axios response and error models to appropriate front end API models,
 * creating a boundary for isolation.
 * Please note that the data models are still returned in raw type to be mapped on the client methods
 * of this class.
 */
class BaseAPI {
    static async get<T>(path: string, id?: string, config?: AxiosRequestConfig): Promise<ApiResponse<T>> {
        let result: ApiResponse<T>
        try {
            result = await axiosInstance.get(`${BASE_URL}${path}/${id}`, config).then(mapAxiosResponse)
        } catch (error) {
            result = mapAxiosResponseError(error)
        }
        return result
    }

    static async getMany<T>(path: string, id?: string, config?: AxiosRequestConfig): Promise<ApiResponse<T[]>> {
        let result: ApiResponse<T[]>
        try {
            const idPath = id ? `/${id}` : ''
            result = await axiosInstance.get(`${BASE_URL}${path}${idPath}`, config).then(mapAxiosResponse)
        } catch (error) {
            result = mapAxiosResponseError(error)
        }

        return result
    }

    static async create<T>(path: string, item: T): Promise<ApiResponse<T>> {
        let result: ApiResponse<T>
        try {
            result = await axiosInstance.post(`${BASE_URL}${path}`, item).then(mapAxiosResponse)
        } catch (error) {
            result = mapAxiosResponseError(error)
        }

        return result
    }

    static async update<T>(path: string, item: T): Promise<ApiResponse<T>> {
        let result: ApiResponse<T>
        try {
            result = await axiosInstance.put(`${BASE_URL}${path}`, item).then(mapAxiosResponse)
        } catch (error) {
            result = mapAxiosResponseError(error)
        }

        return result
    }

    static async patch<T>(path: string, item?: T, config?: AxiosRequestConfig): Promise<ApiResponse<T>> {
        let result: ApiResponse<T>
        try {
            result = await axiosInstance.patch(`${BASE_URL}${path}`, item, config).then(mapAxiosResponse)
        } catch (error) {
            result = mapAxiosResponseError(error)
        }

        return result
    }

    static async delete<T>(path: string, config?: AxiosRequestConfig): Promise<ApiResponse<T>> {
        let result: ApiResponse<T>
        try {
            result = await axiosInstance.delete(`${BASE_URL}${path}`, config).then(mapAxiosResponse)
        } catch (error) {
            result = mapAxiosResponseError(error)
        }
        return result
    }

    static async getBlob(path: string, id: string, config?: any): Promise<ApiResponse<Blob | MediaSource>> {
        let result: ApiResponse<Blob | MediaSource>
        try {
            result = await axiosInstance.get(`${BASE_URL}${path}/${id}`, config).then(mapAxiosResponse)
        } catch (error) {
            result = mapAxiosResponseError(error)
        }
        return result
    }
}

export default BaseAPI
