import { Box, Button, Grid, IconButton, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import MenuIcon from '@mui/icons-material/Menu'
import { useCallback, useContext, useEffect, useState } from 'react'
import { InfoRounded, UnfoldLess, UnfoldMore, Visibility, VisibilityOff } from '@mui/icons-material'
import SearchText from '../../../../components/SearchText'
import { NoteCard, NoteCardAttributes, NotesCardState, mapNotesCardAttributes } from './NotesCard'
import { MetabolicPathwaySidebar } from './MetabolicPathwaySidebar'
import { ExpandState, MetabolicPathwayGrid } from './MetabolicPathwayGrid'
import { highlightFullPathways, highlightNamingPathways, mapMetabolicPathways } from './MetabolicPathways.service'
import { SIDEBAR_WIDTH } from '../SidebarDrawer'
import { NotesCardDrawer } from '../NotesCardDrawer'
import { SampleDataContext } from '../../../../context/CustomContext'
import { FUNCTIONAL_INFERENCE_DB_DATA } from '../../InferencePathwayDB'
import { useNotificationContext } from '../../../../context/NotificationContext'
import { getFunctionalInference } from '../../../../libs/api/functional-inference/FunctionalInferenceAPI'

export interface MetabolicPathwayItem {
    ssuRunId: string
    metabolicPathway: MetabolicPathwaysData
}

export interface MetabolicPathwaysData {
    originalPathways: CombinedMetabolicPathways
    searchedPathways: CombinedMetabolicPathways
}

export interface CombinedMetabolicPathways {
    namingPathways: any[]
    fullPathways: any[]
}

export interface NoteCardAttributeItem {
    ssuRunId: string
    notecardAttributes: NoteCardAttributes[]
}

export interface MetabolicPathwaysProps {
    ssuRunIds: string[]
    metabolicPathways: MetabolicPathwayItem[]
    noteCardAttributeItems: NoteCardAttributeItem[]
    setMetabolicPathways: (metabolicPathways: MetabolicPathwayItem[]) => void
    setNoteCardAttributeItems: (noteCardAttributeitems: NoteCardAttributeItem[]) => void
}

export function MetabolicPathways(props: MetabolicPathwaysProps) {
    const { ssuRunIds, metabolicPathways, noteCardAttributeItems, setMetabolicPathways, setNoteCardAttributeItems } = props
    const { selectedSsuRunId, setExportButtonEnabled } = useContext(SampleDataContext)
    const openNotification = useNotificationContext().openNotification

    const [showSidebar, setShowSidebar] = useState<boolean>(true)
    const [notesCardState, setNotesCardState] = useState<NotesCardState>(NotesCardState.Disabled)
    const [showHierarchy, setShowHierarchy] = useState(true)
    const [expandState, setExpandState] = useState<ExpandState>(ExpandState.neutral)
    const [loading, setLoading] = useState<boolean>(false)

    const [selectedMetabolicPathway, setSelectedMetabolicPathway] = useState<MetabolicPathwaysData>()
    const [searchedMetabolicPathway, setSearchedMetabolicPathway] = useState<MetabolicPathwaysData>()

    const [selectedNoteCardAttributes, setSelectedNoteCardAttributes] = useState<NoteCardAttributes[]>([])

    const [searchTerm, setSearchTerm] = useState<string>('')

    const loadMetabolicPathway = useCallback(
        async (ssuRunId: string) => {
            setLoading(true)
            const currentMetabolicPathways = metabolicPathways || []
            const { succeeded, data, errors } = await getFunctionalInference(ssuRunId)
            if (succeeded) {
                const noteCardItems = mapNotesCardAttributes(data)
                const currNoteCardAttributes = noteCardAttributeItems || []
                setNoteCardAttributeItems([...currNoteCardAttributes, { ssuRunId: ssuRunId, notecardAttributes: noteCardItems }])

                const pathway = mapMetabolicPathways(data.pathways, FUNCTIONAL_INFERENCE_DB_DATA)

                if (pathway) {
                    setExportButtonEnabled(true)
                    setMetabolicPathways([...currentMetabolicPathways, { ssuRunId: ssuRunId, metabolicPathway: pathway }])
                } else {
                    setMetabolicPathways([...currentMetabolicPathways, { ssuRunId: ssuRunId, metabolicPathway: null }])
                }
            } else {
                setMetabolicPathways([...currentMetabolicPathways, { ssuRunId: selectedSsuRunId, metabolicPathway: null }])
                openNotification(errors.message, errors.severity)
            }
            setLoading(false)
        },
        [metabolicPathways, noteCardAttributeItems]
    )

    useEffect(() => {
        const currSelectedMetabolicPathway = metabolicPathways && metabolicPathways.find((x) => x.ssuRunId === selectedSsuRunId)
        if (currSelectedMetabolicPathway) {
            setSelectedMetabolicPathway(currSelectedMetabolicPathway.metabolicPathway)
        } else if (selectedSsuRunId) {
            loadMetabolicPathway(selectedSsuRunId)
        }
    }, [selectedSsuRunId, metabolicPathways])

    useEffect(() => {
        const newNoteCardAttributes = noteCardAttributeItems.find((x) => x.ssuRunId === selectedSsuRunId)
        setSelectedNoteCardAttributes(newNoteCardAttributes?.notecardAttributes || [])
    }, [selectedSsuRunId, noteCardAttributeItems])

    useEffect(() => {
        if (expandState !== ExpandState.neutral) {
            setExpandState(ExpandState.neutral)
        }
    }, [expandState])

    const filterMetabolicPathways = useCallback(
        (term: string) => {
            const lowerCaseTerm = term.toLowerCase()

            const searchedNamingPathways = highlightNamingPathways(lowerCaseTerm, selectedMetabolicPathway?.originalPathways.namingPathways)
            const searchedFullPathways = highlightFullPathways(lowerCaseTerm, selectedMetabolicPathway?.originalPathways.fullPathways)
            setSearchedMetabolicPathway({
                ...selectedMetabolicPathway,
                searchedPathways: {
                    namingPathways: searchedNamingPathways,
                    fullPathways: searchedFullPathways,
                },
            })

            if (term.length > 0 && (searchedFullPathways.length > 0 || searchedNamingPathways.length > 0)) {
                setExpandState(ExpandState.expand)
            } else {
                setExpandState(ExpandState.collapse)
            }
        },
        [selectedMetabolicPathway]
    )

    useEffect(() => {
        if (selectedMetabolicPathway) {
            filterMetabolicPathways(searchTerm)
        }
    }, [searchTerm, selectedMetabolicPathway])

    const noData = !selectedSsuRunId || (selectedMetabolicPathway && !selectedMetabolicPathway.originalPathways)

    return (
        <>
            {noData ? (
                <Box id="taxonomy" sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Grid sx={{ marginTop: '200px' }}>
                        <Typography variant="h4">Metabolic Pathway data is not available for the sample</Typography>
                    </Grid>
                </Box>
            ) : (
                <Box sx={{ display: 'flex', width: '100%' }}>
                    <MetabolicPathwaySidebar open={showSidebar} ssuRunIds={ssuRunIds} onClose={() => setShowSidebar(false)} />
                    <Main open={showSidebar} sx={{ width: 'auto', flex: 1, overflow: 'hidden' }}>
                        <Grid display="flex" flexDirection="column" alignItems="flex-start">
                            <Grid display="flex" flexDirection="row">
                                <IconButton
                                    onClick={() => setShowSidebar(true)}
                                    edge="start"
                                    color="inherit"
                                    aria-label="open drawer"
                                    sx={{
                                        mar: 2,
                                        paddingTop: '2px',
                                        ...(showSidebar && { display: 'none' }),
                                    }}
                                >
                                    <MenuIcon />
                                </IconButton>
                                <Typography variant="h5">{selectedSsuRunId}</Typography>
                            </Grid>
                            <Grid
                                sx={{
                                    display: 'flex',
                                    width: '100%',
                                    height: '40px',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Button disabled={!noteCardAttributeItems} onClick={() => setNotesCardState(NotesCardState.Attributes)} sx={{ alignSelf: 'flex-end' }}>
                                    <Typography variant="body2" textTransform="capitalize" fontWeight={500}>
                                        View attributes
                                    </Typography>
                                </Button>
                                <Button onClick={() => setNotesCardState(NotesCardState.Helper)} sx={{ alignSelf: 'flex-start' }}>
                                    <InfoRounded />
                                    <Typography variant="body2" textTransform="capitalize" fontWeight={500} sx={{ marginLeft: '10px' }}>
                                        Term Helper
                                    </Typography>
                                </Button>
                            </Grid>
                            <Grid display="flex" width="100%" flexDirection="row" justifyContent={'space-between'} alignItems="center">
                                <SearchText
                                    label="Seach by keyword..."
                                    disabled={false}
                                    onSearchChange={setSearchTerm}
                                    sx={{
                                        width: '30%',
                                        minWidth: '250px',
                                        marginTop: '15px',
                                    }}
                                />
                                <Grid>
                                    <Button onClick={() => setShowHierarchy(!showHierarchy)}>
                                        {showHierarchy ? <VisibilityOff sx={{ color: 'black' }} /> : <Visibility sx={{ color: 'black' }} />}
                                        <Typography
                                            variant="body2"
                                            textTransform="capitalize"
                                            sx={{
                                                color: 'rgba(34, 34, 58, 1)',
                                                marginLeft: '5px',
                                                padding: '4px',
                                                fontWeight: 500,
                                            }}
                                        >
                                            {showHierarchy ? 'Hide hierarchy' : 'Show hierarchy'}
                                        </Typography>
                                    </Button>
                                    <Button onClick={() => setExpandState(ExpandState.expand)} sx={{ marginLeft: '10px' }}>
                                        <UnfoldMore sx={{ color: 'black' }} />
                                        <Typography
                                            variant="body2"
                                            textTransform="capitalize"
                                            sx={{
                                                color: 'rgba(34, 34, 58, 1)',
                                                marginLeft: '5px',
                                                padding: '4px',
                                                fontWeight: 500,
                                            }}
                                        >
                                            Expand all
                                        </Typography>
                                    </Button>
                                    <Button onClick={() => setExpandState(ExpandState.collapse)} sx={{ marginLeft: '10px' }}>
                                        <UnfoldLess sx={{ color: 'black' }} />
                                        <Typography
                                            variant="body2"
                                            textTransform="capitalize"
                                            sx={{
                                                color: 'rgba(34, 34, 58, 1)',
                                                marginLeft: '5px',
                                                padding: '4px',
                                                fontWeight: 500,
                                            }}
                                        >
                                            Collapse all
                                        </Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                            <MetabolicPathwayGrid
                                runId={selectedSsuRunId}
                                metabolicPathways={
                                    showHierarchy ? searchedMetabolicPathway?.searchedPathways.fullPathways : searchedMetabolicPathway?.searchedPathways.namingPathways
                                }
                                showHierarchy={showHierarchy}
                                expandState={expandState}
                                loading={loading}
                            />
                        </Grid>
                    </Main>
                    <NotesCardDrawer
                        open={notesCardState !== NotesCardState.Disabled}
                        title={notesCardState === NotesCardState.Attributes ? 'Attributes' : 'Term Helper'}
                        onClose={() => setNotesCardState(NotesCardState.Disabled)}
                    >
                        <NoteCard notesCardState={notesCardState} noteCardItems={notesCardState === NotesCardState.Attributes ? selectedNoteCardAttributes : null} />
                    </NotesCardDrawer>
                </Box>
            )}
        </>
    )
}

// Styles main component based on sidebar state, including open/close transition
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean
}>(({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeIn,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
    ...(!open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${SIDEBAR_WIDTH}`,
    }),
}))
