import { SampleDTO } from './SampleDTO'
import { Location, Sample, SampleType, SamplingMethod, ShippingCondition, WaterPreservationMethod, Weather } from './SampleModel'

export const mapSampleDtoToSample = (sampleDTO: SampleDTO): Sample => {
    const sample: Sample = {
        id: sampleDTO.id,
        checkNumber: sampleDTO.checkNumber,
        legacyBarcode: sampleDTO.legacyBarcode,
        userDefinedSampleId: sampleDTO.userDefinedSampleId,
        projectId: sampleDTO.projectId,
        projectName: sampleDTO.projectName,
        company: sampleDTO.company,
        site: sampleDTO.site,
        weather: sampleDTO.weather as Weather,
        shippingCondition: sampleDTO.shippingCondition as ShippingCondition,
        sampleType: sampleDTO.sampleType as SampleType,
        samplingMethod: sampleDTO.samplingMethod as SamplingMethod,
        status: sampleDTO.status,
        collectionDate: sampleDTO.collectionDate,
        source: sampleDTO.source,
        depth: sampleDTO.depth,
        elevation: sampleDTO.elevation,
        temperature: sampleDTO.temperature,
        location: sampleDTO.location as Location,
        ph: sampleDTO.ph,
        do: sampleDTO.do,
        orp: sampleDTO.orp,
        conductivity: sampleDTO.conductivity,
        waterPreservation: sampleDTO.waterPreservation as WaterPreservationMethod,
        waterQualitySampleId: sampleDTO.waterQualitySampleId,
        sampleLocationId: sampleDTO.sampleLocationId,
        samplingNotes: sampleDTO.samplingNotes,
        processingNotes: sampleDTO.processingNotes,
        createdById: sampleDTO.createdById,
        createdByEmail: sampleDTO.createdByEmail,
        publicSampleProps: sampleDTO.publicSampleProps,
    }

    return sample
}

export const mapSampleToSampleDto = (sample: Sample): SampleDTO => {
    return sample as SampleDTO
}
